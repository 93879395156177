:root {
  --font-min: 10px;
  --font-smallest: 11px;
  --font-small: 12px;
  --font-regular: 14px;
  --font-medium: 15px;
  --font-default: 16px;
  --font-large: 18px;
  --font-larger: 28px;
  --font-largest: 32px;
  --font-title: 38px;
  --font-max: 40px;

  --default-font-color: #061938;
  --white-font-color: #fff;
  --backgroundWhite: #fff;

  --primary-color: #54006e;
  --secondary-color: #ffab00;
  --default-color: #426fa1;
  --progress-bar-backrgound: #ffb905;

  --dark-gray: #444444;
  --regular-gray: #555555;
  --medium-gray: #7d8073;
  --default-gray: #d9d9d9;
  --light-gray: #e6e6e6;

  --primary-color-hover: #46005e;
  --secondary-color-hover: #f2a100;
}

* {
  font-family: 'Objektiv', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
}

button * {
  pointer-events: none;
}

.content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-modal-overlay::-webkit-scrollbar {
  display: none;
}

.react-modal-content {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
